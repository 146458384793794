import React from "react";
import Footer from "../common/Footer";
import Cenniczek from "./Cenniczek";

const RealizationsLayout = () => {
  return (
    <>
      <Cenniczek />
      <Footer />
    </>
  );
};
export default RealizationsLayout;
