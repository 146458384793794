import React from "react";
import RealizationsLayout from "../layouts/realizations/RealizationsLayout";
const Realizations = () => {
  return (
    <>
      <RealizationsLayout />
    </>
  );
};
export default Realizations;
